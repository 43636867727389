<template>
  <span
    class="flex items-center gap-vw-4-to-9 rounded-full text-vw-in-pill"
    :class="{
      'p-vw-6-to-9 md:h-vw-48-to-100 md:flex-col md:pb-vw-4-to-16 md:pt-vw-2-to-11':
        verticalOnDesktop,
      'py-vw-6-to-16 pl-vw-6-to-14 pr-vw-6-to-16': !verticalOnDesktop,
    }"
  >
    <slot></slot>
  </span>
</template>
<script lang="ts" setup>
defineProps<{
  verticalOnDesktop?: boolean
}>()
</script>
